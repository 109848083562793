<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-toolbar-title>Create New Outgoing Payment</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          small
          text
          to="/banking/outgoing-payments/on-account"
        >
          <v-icon
            left
            dark
          >mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- editor -->
        <editor
          :editMode="editMode"
          :tenantData="tenantData"
          :currencies="currencies"
          :initial="dataItem"
          @data="save"
        ></editor>
        <!-- end -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: { parent: false, inv1: [] },
    tenantData: {},
    editMode: false,
    currencies: [],
  }),
  methods: {
    save(data) {
      console.log(data);
      const url = "/outgoing_payment";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          console.log(res, "r");
          // self.$refs.snackbar.show("Item group created", "green");
          self.$router.push("/banking/outgoing-payments/on-account");
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {},
};
</script>